import React from 'react'

import { Box, Container, Flex } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'

const Content = () => (
  <Box as="section" bg="red.100" py={6} px={4} id="services">
    <Container>
      <SectionTitle color="primary.900">COOKIES POLICY</SectionTitle>
      <Box bg="white" position="relative" px={6} py={6} zIndex="2" width="100%">
        <Text variant="body">
          In compliance with the provisions of article 22.2 of Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce, this website informs you, in this section, about the collection policy and treatment of cookies.
        </Text>
        <Text as="h3" color="primary.900">
          WHAT ARE COOKIES?
        </Text>
        <Text variant="body">
          A cookie is a file that is downloaded to your computer when you access certain web pages. Cookies allow a web page, among other things, to store and retrieve information about the browsing habits of a user or their equipment and, depending on the information they contain and the way they use their equipment, they can be used to recognize to user.
        </Text>
        <Text as="h3" color="primary.900">
          WHO USES THE INFORMATION STORED IN THE COOKIES?
        </Text>
        <Text variant="body">
          The information stored in our website's cookies is used exclusively by
          us, with the exception of those identified below as "third party
          cookies", which are used and managed by external entities to provide
          services requested by us to improve our services and the user's
          experience when browsing our website.
        </Text>
        <Text as="h3" color="primary.900">
          WHAT TYPES OF COOKIES DOES THIS WEBSITE USE?
        </Text>
        <Text variant="body">
          This website uses the following types of cookies:
          <br />
          <br />
          <b>Analysis cookies: </b>
          They are those that are well treated by us or by third parties, allow us to quantify the number of users and thus carry out the statistical measurement and analysis of the use that users make of the service offered. For this, your browsing on our website is analyzed in order to improve the offer of products or services that we offer you.
          <br />
          <br />
          <b>Technical cookies: </b>
          These are those that allow the user to navigate through the web forms.
          <br />
          <br />
          <b>Advertising cookies:</b> These are those that, well treated by this website or by third parties, allow the offer of advertising spaces on the website to be managed in the most efficient way possible, adapting the content of the advertisement to the content of the requested service or use. you make from our website. For this we can analyze your browsing habits on the Internet and we can show you advertising related to your browsing profile.        </Text>
        <Text as="h3" color="primary.900">
          DEACTIVATE COOKIES
        </Text>
        <Text variant="body">
          You can allow, block or delete the cookies installed on your computer by configuring the browser options installed on your computer.
          <br />
          <br />
          Most web browsers offer the possibility of allowing, blocking or eliminating the cookies installed on your computer.
          <br />
          <br />
          Below you can access the configuration of the most frequent web browsers to accept, install or deactivate cookies:
          <br />
          <br />
          <ul>
            <li>
              <span style={{ fontWeight: '400' }}>Chrome&nbsp;[</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.google.com/chrome/answer/95647?hl=en"
              >
                <span style={{ fontWeight: '400' }}>
                  https://support.google.com/chrome/answer/95647?hl=en
                </span>
              </a>
              <span style={{ fontWeight: '400' }}>].&nbsp;</span>
            </li>
            <li>
              <span style={{ fontWeight: '400' }}>Explorer&nbsp;[</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
              >
                <span style={{ fontWeight: '400' }}>
                  https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11
                </span>
              </a>
              <span style={{ fontWeight: '400' }}>].&nbsp;</span>
            </li>
            <li>
              <span style={{ fontWeight: '400' }}>Firefox&nbsp;[</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.mozilla.org/en/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
              >
                <span style={{ fontWeight: '400' }}>
                  https://support.mozilla.org/en/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
                </span>
              </a>
              <span style={{ fontWeight: '400' }}>].&nbsp;</span>
            </li>
            <li>
              <span style={{ fontWeight: '400' }}>Safari&nbsp;[</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
              >
                <span style={{ fontWeight: '400' }}>
                  https://support.apple.com/en-gb/guide/safari/sfri11471/mac
                </span>
              </a>
              <span style={{ fontWeight: '400' }}>].&nbsp;</span>
            </li>
          </ul>
          We also remind you that you can use the private browsing mode in your
          browser, so your browser will no longer save your browsing history,
          cookies and other website information.
        </Text>
        <Text as="h3" color="primary.900">
          THIRD PARTY COOKIES
        </Text>
        <Text variant="body">
          Specifically, we use Google Adsense and Google Analytics services for our statistics and advertising. Some cookies are essential for the operation of the site, for example the built-in search engine.
        </Text>
        <Text as="h3" color="primary.900">
          WARNING ABOUT DELETING COOKIES
        </Text>
        <Text variant="body">
          Warning about deleting cookies.
        <br />
          <br />
You can delete and block all cookies on this site, but part of the site will not work or the quality of the web page may be affected.
<br />
          <br />
If you have any questions about our cookie policy, you can contact this website through our Contact channels.
        </Text>
      </Box>
    </Container>
  </Box>
)

export default Content
