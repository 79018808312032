import React from 'react'

import { Layout } from '@components/LayoutEN'
import { SEO } from '@components/SEO'
import { Content } from '../screens/CookiesEN'
import { navigate } from 'gatsby';

const CookiesPolicies = () => {

  if (typeof window !== 'undefined' && window.location.pathname === '/cookiesPolicies') {
    navigate('/es/cookiesPolicies');
  }
  return (
      <Layout>
        <SEO title="Cookies Policies" />
        <Content />
      </Layout>
  )
}

export default CookiesPolicies
